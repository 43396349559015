/** @flow
 * StratoDem Analytics : pricing
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';

import {Layout, SEO} from '../components';

import './index.scss';
import './faq.scss';
import './pricing.scss';
import {graphql} from "gatsby";
import Img from "gatsby-image";

type
Props = {
  data: {
    maintenanceAvatar: {
      childImageSharp: {
        fluid: any,
      }
    }
  },
};

const Maintenance = (props: Props) => (
  <Layout>
    <SEO
      title="We're hard at work updating something"
      description="StratoDem maintenance page"
      keywords={[]}
    />
    <div style={{textAlign: 'center', backgroundColor: '#f6f9fc', padding: '150px 20%', minHeight: '100vh'}}>
      <div style={{
        boxShadow: '0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07)',
        padding: '28px 21px',
        backgroundColor: '#fff',
        borderRadius: 14,
      }}>
        <div style={{textAlign: 'center', maxWidth: 200, margin: '0 auto'}}>
          <Img fluid={props.data.maintenanceAvatar.childImageSharp.fluid}/>
        </div>
        <h1 style={{marginTop: 0, fontSize: 28, color: '#000'}}>Analytics engine temporarily unavailable</h1>
        <div>
          <p style={{lineHeight: '35px', fontSize: 18, color: '#333'}}>
            We&rsquo;re performing some site maintenance at the moment. If you need any help, you can always&nbsp;
            <a href="mailto:sd@stratodem.com" target="_blank" rel="noopener">get in touch &rarr;</a><br/>
            We&rsquo;ll be back online shortly!
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Maintenance;

export const pageQuery = graphql`
    query {
      maintenanceAvatar: file(relativePath: { eq: "SDMaintenanceAvatar.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`;
